import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageContact.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import ContentPageSize from '../Layout/ContentPageSize';
import ColumnContainer from '../Columns/ColumnContainer';
import Column from '../Columns/Column';
import FlexImage from '../FlexContent/FlexImage';
import Parse from '../Parse/Parse';

const PageContact = ({ page, ...rest }) => {
    const imgs = page.hgGallery && page.hgGallery.hgGalleryField;

    const image1 = imgs && imgs[0] || undefined;
    const image2 = imgs && imgs[1] || undefined;

    return (
        <ContentPageSize {...rest}>
            <ColumnContainer>
                <Column
                    styleName="acf-hg-third"
                    className={styles.addressColumn}
                >
                    <div className={styles.flexCol}>
                        <Parse
                            className={styles.addressColumn}
                            html={page.content}
                        />
                        <FlexImage className={styles.bregtFoto} item={image1} />
                    </div>
                </Column>
                <Column styleName="acf-hg-twothird">
                    <FlexImage item={image2} />
                </Column>
            </ColumnContainer>
        </ContentPageSize>
    );
};

PageContact.defaultProps = {
    page: undefined
};

PageContact.propTypes = {
    page: PropTypes.object
};

export default React.memo(withClassNames(PageContact, styles));

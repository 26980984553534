import React from 'react';
import PropTypes from 'prop-types';
import styles from './PostFull.module.scss';
import { withClassNames } from '../HigherOrder/withClassNames';
import Text from '../Text/Text';
import Divider from '../Divider/Divider';
import ContentPageSize from '../Layout/ContentPageSize';
import ColumnContainerComposition from '../../Compositions/ColumnContainerComposition';
import PostHelmet from '../Helmet/PostHelmet';

const PostFull = ({ post, ...rest }) => {
    const fullTitle =
        post.hgPostTitles.hgLongTitle ||
        `${post.hgPostTitles.hgSubtitle || ''} ${post.title}`;
    return (
        <ContentPageSize {...rest}>
            <PostHelmet
                title={fullTitle}
                description={post.excerpt.replace(/<[^>]+>/g, '')}
                thumbnailUrl={
                    post.featuredImage ? post.featuredImage.sourceUrl : ''
                }
            />
            <div className={styles.pageHead}>
                <Text className={styles.fullTitle}>{fullTitle}</Text>
                <Divider />
            </div>
            <ColumnContainerComposition columns={post.hgColumns} post={post} />
        </ContentPageSize>
    );
};

PostFull.defaultProps = {
    post: undefined
};

PostFull.propTypes = {
    post: PropTypes.object
};

export default React.memo(withClassNames(PostFull, styles));

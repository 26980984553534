export const ROOT_PATH = process.env.REACT_APP_ROOT_PATH;

export const WORDPRESS_URL = `${process.env.REACT_APP_WORDPRESS_ROOT}${process.env.REACT_APP_ROOT_PATH}${process.env.REACT_APP_WORDPRESS_PATH}`; // allSettings > generalSettingsUrl
export const GRAPHQL_PATH = `${WORDPRESS_URL}graphql`; // make sure your permalinks are set to 'post name'

export const POST_PAGE_PATH = 'projecten/';
export const POST_SINGLE = `${ROOT_PATH}${POST_PAGE_PATH}:slug`;
export const POSTS_ALL = `${ROOT_PATH}${POST_PAGE_PATH}`;
export const PAGE_SINGLE = `${ROOT_PATH}:slug`;
export const SLUG_CONTACT = 'contact';

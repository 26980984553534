import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import HelmetConfig from './HelmetConfig';

const PostHelmet = ({ title, description, thumbnailUrl }) => {
    const descr = description ? description.substring(0, 155) : title;
    return (
        <Helmet {...HelmetConfig}>
            <title>{title}</title>
            <meta name="description" content={descr} />
            <meta property="og:title" content={`${title} | Bregt Balk`} />
            <meta property="og:description" content={descr} />
            <meta property="og:type" content="website" />
            {thumbnailUrl && (
                <meta property="og:image" content={thumbnailUrl} />
            )}
            {thumbnailUrl && <meta property="og:image:alt" content={title} />}
            {thumbnailUrl && <meta property="og:image:width" content="512" />}
            {thumbnailUrl && <meta property="og:image:height" content="288" />}
        </Helmet>
    );
};
PostHelmet.defaultProps = {
    title: undefined,
    description: undefined,
    thumbnailUrl: undefined
};

PostHelmet.propTypes = {
    title: PropTypes.string, // eslint-disable-line react/forbid-prop-types
    description: PropTypes.string,
    thumbnailUrl: PropTypes.string
};

export default PostHelmet;

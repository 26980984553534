import React from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import QueryContainer from '../Containers/QueryContainer';
import PostFull from '../Components/Post/PostFull';

const POST_QUERY = gql`
    query PageQuery($slug: ID!) {
        post(id: $slug, idType: URI) {
            id
            title
            excerpt(format: RENDERED)
            content(format: RENDERED)
            hgPostTitles {
                hgLongTitle
                hgSubtitle
            }

            featuredImage {
                sourceUrl(size: LARGE)
                srcSet(size: LARGE)
                sizes(size: LARGE)
            }
            hgColumns {
                hgColumngroup {
                    fieldGroupName
                    hgColumn {
                        fieldGroupName
                        hgColumnFormaat
                        hgContentFlex {
                            ... on Post_Hgcolumns_hgColumngroup_hgColumn_HgContentFlex_Titel {
                                hgFlexContentTitel
                            }
                            ... on Post_Hgcolumns_hgColumngroup_hgColumn_HgContentFlex_Tekst {
                                hgFlexContentText
                            }
                            ... on Post_Hgcolumns_hgColumngroup_hgColumn_HgContentFlex_Beeld {
                                fieldGroupName
                                hgFlexContentBeeld {
                                    altText
                                    srcSet(size: LARGE)
                                    sourceUrl(size: LARGE)
                                }
                            }
                            ... on Post_Hgcolumns_hgColumngroup_hgColumn_HgContentFlex_Hoofdtekst {
                                fieldGroupName
                                hgFlexContentHoofdtekst
                            }
                        }
                    }
                }
            }
        }
    }
`;

const SinglePost = () => {
    const { slug } = useParams();
    return (
        <QueryContainer query={POST_QUERY} variables={{ slug }}>
            {(data) => <PostFull post={data.post} />}
        </QueryContainer>
    );
};

export default React.memo(SinglePost);

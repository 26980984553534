const HelmetConfig = {
    titleTemplate: '%s | Bregt Balk',
    defaultTitle: 'Bregt Balk',
    meta: [
        { property: 'og:site_name', content: 'Bregt Balk' },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'website' }
    ]
};

export default HelmetConfig;
